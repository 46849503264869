<template>
	<div>
		<Header :navbg='true'></Header>
		<div class="content">
			<van-swipe :autoplay="3000" indicator-color="white" class="banner">
				<van-swipe-item v-for="(img,index) in bannerList" :key="index">
					<a :href="img.link" class="banner-warp">
						<!-- <h3 class="title">{{img.title}}</h3> -->
						<p class="desc">{{img.desc}}</p>
						<img :src="img.image" class="banner-img">
					</a>
				</van-swipe-item>
			</van-swipe>
			<div class="wrap wrap1">
				<div class="page">
					<div class="title">
						核心功能
					</div>
					<div class="subhead">
						悦享品质生活
					</div>
					<div class="img-list">
						<div class="img-item">
							<img src="../../assets/images/lifehall/book.jpg" alt="">
							<div class="name">图书</div>
						</div>
						<div class="img-item">
							<img src="../../assets/images/lifehall/kafe.jpg" alt="">
							<div class="name">咖啡</div>
						</div>
						<div class="img-item">
							<img src="../../assets/images/lifehall/cake.jpg" alt="">
							<div class="name">甜品</div>
						</div>
						<div class="img-item">
							<img src="../../assets/images/lifehall/gym.jpg" alt="">
							<div class="name">健身</div>
						</div>
					</div>
				</div>

			</div>

			<div class="wrap wrap2">
				<div class="title">小院生活馆</div>
				<div class="desc">提供与消费者居住体验相关的</div>
				<div class="desc">全周期配套服务</div>
				<div class="store-list">

					<div class="store-item">
						<div class="page">
							<img src="../../assets/images/lifehall/jwwl.jpg" alt="">
							<div class="store-wrap">
								<div class="name">西安泾渭五路店</div>
								<div class="desc">小院生活·泾渭五路店旨为用户打造一个休闲、娱乐交互空间，生活馆提供饮品、咖啡、图书等服务项目。</div>
								<div class="mobile-wrap">
									<span class="iconfont icon-phone_light"></span>
									<span class="mobile">
										029-86039888
									</span>
								</div>
								<div class="time-wrap">
									<span class="iconfont icon-time1"></span>
									<span class="time">
										周一到周日 8:00-20:00
									</span>
								</div>
								<div class="address-wrap">
									<span class="iconfont icon-location_light"></span>
									<span class="address">
										西安市高陵区泾渭5路小院生活
									</span>
								</div>
							</div>
						</div>
					</div>
					<div class="store-item">
						<div class="page">
							<img src="../../assets/images/lifehall/ayhf.jpg" alt="">
							<div class="store-wrap">
								<div class="name">西安奥韵华府店</div>
								<div class="desc">小院生活·奥韵华府店服务包含饮品、图书借阅、休闲交互。恬静、广阔的空间让大家更好的体验属于自己的私人第三空间。</div>
								<div class="mobile-wrap">
									<span class="iconfont icon-phone_light"></span>
									<span class="mobile">
										029-86013666
									</span>
								</div>
								<div class="time-wrap">
									<span class="iconfont icon-time1"></span>
									<span class="time">
										周一到周日 9:00-20:00
									</span>
								</div>
								<div class="address-wrap">
									<span class="iconfont icon-location_light"></span>
									<span class="address">
										西安市高陵区昭慧路奥韵华府
									</span>
								</div>
							</div>
						</div>
					</div>
					<div class="store-item">
						<div class="page">
							<img src="../../assets/images/lifehall/hfgc.jpg" alt="">
							<div class="store-wrap">
								<div class="name">渭南宏帆广场店</div>
								<div class="desc">小院生活·宏帆馆服务项目涵盖读书、茶饮、甜品、精品手冲等，旨在打造家庭、工作以外的第三生活空间，以高端的品质、完美的细节打造都市人心中向往的生活高地。</div>
								<div class="mobile-wrap">
									<span class="iconfont icon-phone_light"></span>
									<span class="mobile">
										0913-2071999
									</span>
								</div>
								<div class="time-wrap">
									<span class="iconfont icon-time1"></span>
									<span class="time">
										周一至周日 10:00-22:00
									</span>
								</div>
								<div class="address-wrap">
									<span class="iconfont icon-location_light"></span>
									<span class="address">
										渭南市临渭区宏帆广场A馆2层AZ-L2-10号商铺
									</span>
								</div>
							</div>
						</div>
					</div>
					<div class="store-item">
						<div class="page">
							<img src="../../assets/images/lifehall/wdgc.jpg" alt="">
							<div class="store-wrap">
								<div class="name">渭南万达广场店</div>
								<div class="desc">小院生活·万达馆主营读书、咖啡、茶饮、甜品等，店面风格将现代与田园完美融合，为消费者带来高品质的美好生活体验。</div>
								<div class="mobile-wrap">
									<span class="iconfont icon-phone_light"></span>
									<span class="mobile">
										0913-2071999
									</span>
								</div>
								<div class="time-wrap">
									<span class="iconfont icon-time1"></span>
									<span class="time">
										周一至周日 10:00-22:00
									</span>
								</div>
								<div class="address-wrap">
									<span class="iconfont icon-location_light"></span>
									<span class="address">
										渭南市新区万达广场3层3030号商铺
									</span>
								</div>
							</div>
						</div>
					</div>

					<div class="store-item">
						<div class="page">
							<img src="../../assets/images/lifehall/mopark.jpg" alt="">
							<div class="store-wrap">
								<div class="name">西安MOMOPARK店</div>
								<div class="desc">小院生活·MOMOPARK店（华夫茶饼店）是小院首家亲子主题门店，产品以华夫饼、咖啡、鲜榨果汁为主，打造全新的轻业态华夫体验场馆。</div>
								<div class="mobile-wrap">
									<span class="iconfont icon-phone_light"></span>
									<span class="mobile">
										18710711507
									</span>
								</div>
								<div class="time-wrap">
									<span class="iconfont icon-time1"></span>
									<span class="time">
										周一至周日 10:00-22:00
									</span>
								</div>
								<div class="address-wrap">
									<span class="iconfont icon-location_light"></span>
									<span class="address">
										西安市雁塔区小寨西路232号MOMOPARK F3
									</span>
								</div>
							</div>
						</div>
					</div>
			<!-- 		<div class="store-item">
						<div class="page">
							<img src="../../assets/images/lifehall/momo.jpg" alt="">
							<div class="store-wrap">
								<div class="name">西安小院生活共享自习室</div>
								<div class="desc">小院生活共享自习室主打海量图书免费借阅服务，小程序在线即可完成图书的搜索及借阅，室内环境温馨舒适，提供学习休闲、读书分享、艺术拓展等体验。</div>
								<div class="mobile-wrap">
									<span class="iconfont icon-phone_light"></span>
									<span class="mobile">
										18710711507
									</span>
								</div>
								<div class="time-wrap">
									<span class="iconfont icon-time1"></span>
									<span class="time">
										周一至周日 10:00-22:00
									</span>
								</div>
								<div class="address-wrap">
									<span class="iconfont icon-location_light"></span>
									<span class="address">
										西安市雁塔区小寨西路232号MOMOPARK3层
									</span>
								</div>
							</div>
						</div>
					</div> -->
					<div class="store-item">
						<div class="page">
							<img src="../../assets/images/lifehall/Ycafe.jpg" alt="">
							<div class="store-wrap">
								<div class="name">西安正衡金融广场店</div>
								<div class="desc">小院生活·正衡金融广场店（ Y Cafe），是首家入驻于办公楼内的小院生活馆。店面风格以灰白配色为主基调，简约利落，安静温柔又不乏时尚。工作之余，在繁忙中停下脚步，来这里享受片刻的好时光。</div>
								<div class="mobile-wrap">
									<span class="iconfont icon-phone_light"></span>
									<span class="mobile">
										17349024234
									</span>
								</div>
								<div class="time-wrap">
									<span class="iconfont icon-time1"></span>
									<span class="time">
										周一至周五 8:00-18:00
									</span>
								</div>
								<div class="address-wrap">
									<span class="iconfont icon-location_light"></span>
									<span class="address">
										西安市长安区雁塔南路正衡金融广场A栋1F
									</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="wrap3">
				<Map class="mapbox" :storeList="storeList"></Map>
			</div>
		</div>
		<Footer></Footer>
	</div>
</template>

<script>
	import Header from '@/components/head2022.vue'
	import Footer from '@/components/foot2022.vue'
	import Map from '@/components/map'
	import {
		mapState
	} from 'vuex'
	export default {
		name: "productLifehall",
		data() {
			return {
				bannerList: [],
				storeList: [
					{
						name: "小院生活·大唐芙蓉园龙舫店",
						address: "西安市雁塔区大唐芙蓉园景区龙舫",
						lnglat: [108.971013, 34.213293],
					},
					{
						name: "小院生活·MOMOPARK华夫茶饼店",
						address: "西安市雁塔区小寨西路232号MOMOPARK3层",
						lnglat: [108.933256, 34.221806],
					},
					{
						name: "小院生活·正衡金融广场Y Cafe店",
						address: "西安市长安区雁塔南路正衡金融广场A栋1F",
						lnglat: [108.965209, 34.153392],
					},
					{
						name: "小院生活·泾渭五路店",
						address: "西安市高陵区泾渭5路小院生活",
						lnglat: [ 109.014027, 34.446513]
					},
					{
						name: "小院生活·奥韵华府店",
						address: "西安市高陵区昭慧路奥韵华府",
						lnglat: [109.096955, 34.520673],
					},
					{
						name: "小院生活·宏帆广场店",
						address: "渭南市临渭区宏帆广场A馆2层AZ-L2-10号商铺",
						lnglat: [109.4929440,34.4981870],
					},
					{
						name: "小院生活·万达广场店",
						address: "渭南市新区万达广场3层3030号商铺",
						lnglat: [ 109.4532610 , 34.5064810]
					}
				]
			}
		},
		components: {
			Header,
			Footer,
			Map
		},
		computed: {
			...mapState(['ocApi']),
		},
		created() {
			this.getBanner();
		},
		methods: {
			getBanner: function() {
				let that = this;
				that.$axios.get(that.ocApi + 'book/banner', {
						params: {
							'name': 'mobile_product_lifehall',
						}
					})
					.then(res => {
						if (res.data.status == 1) {
							that.bannerList = res.data.data.images
						} else {
							that.$message.error(res.data.message);
						}
					})
			},
		},
	}
</script>

<style lang="scss" scoped>
	.banner :deep(.van-swipe__indicators) {
		bottom: 30px;
	}
	
	.banner :deep(.van-swipe__indicators) .van-swipe__indicator {
		border-radius: 0;
		width: 16px;
		height: 2px;
		background: #000;
	}
	.banner-warp {
		line-height: 0;
		position: relative;
		display: block;
	}
	
	.banner-warp .title {
		position: absolute;
		top: 200px;
		width: 100%;
		text-align: center;
		color: #fff;
		font-size: 24px;
	}
	
	.banner-warp .desc {
		position: absolute;
		top: 250px;
		width: 100%;
		text-align: center;
		color: #fff;
		font-size: 24px;
	}
	
	.banner-warp .banner-img {
		width: 100%;
	}
	.content {
		width: 100%;
		background-color: #fff;
		.wrap {
			color: #303233;
		}

		.wrap1 {
			padding: 54px 22px;

			.title {
				font-size: 19px;
				line-height: 19px;
				font-weight: 900;
				text-align: center;
			}

			.subhead {
				font-size: 14px;
				font-weight: 400;
				line-height: 14px;
				margin-top: 27px;
				text-align: center;
			}

			.img-list {
				width: 100%;
				display: flex;
				margin-top: 42px;
				flex-wrap: wrap;

				.img-item {
					width: 50%;
					position: relative;

					>img {
						width: 100%;
					}

					.name {
						width: 100%;
						height: 100%;
						position: absolute;
						top: 0;
						left: 0;
						display: flex;
						justify-content: center;
						align-items: center;
						font-size: 15px;
						color: #ffffff;
					}
				}
			}
		}

		.wrap2 {
			background-color: #fafafa;
			padding: 37px 15px 20px;
			width: 100%;
			box-sizing: border-box;

			>.title {
				font-size: 19px;
				color: #303233;
				line-height: 19px;
				text-align: center;
				margin-bottom: 13px;
			}

			>.desc {
				font-size: 14px;
				color: #999999;
				line-height: 19px;
				text-align: center;
			}

			.store-list {
				margin-top: 35px;

				.store-item {
					background-color: #ffffff;
					margin-bottom: 22px;
					box-shadow: 0px 5px 17px 1px rgba(153, 153, 153, 0.1);
					border-radius: 3px;
				}

				.page {
					>img {
						width: 100%;
					}

					.store-wrap {
						width: 100%;
						padding: 34px 18px 28px;
						box-sizing: border-box;
						color: #666666;

						.name {
							font-size: 19px;
							font-weight: 400;
							line-height: 19px;
							text-align: center;
						}

						.desc {
							font-size: 14px;
							font-weight: 400;
							line-height: 24px;
							margin-top: 21px;
							text-align: center;
							color: #999999;
							padding-bottom: 20px;
							border-bottom: 1px solid #cccccc;
						}

						.mobile-wrap {
							font-size: 14px;
							margin-top: 27px;
							display: flex;
							align-items: center;

							.iconfont {
								font-size: 20px;
								margin-right: 8px;
							}
						}

						.time-wrap {
							font-size: 14px;
							margin-top: 10px;
							display: flex;
							align-items: center;

							.iconfont {
								font-size: 17px;
								margin-right: 8px;
							}
						}

						.address-wrap {
							font-size: 14px;
							margin-top: 10px;
							display: flex;
							align-items: center;

							.iconfont {
								font-size: 20px;
								margin-right: 8px;
							}
						}
					}
				}
			}
		}

		.wrap3 {
			.mapbox {
				width: 100%;
				height: 490px;
			}
		}
	}
</style>
